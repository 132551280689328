import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { TAWK_TO_CONFIGS } from '@src/services/.env.js'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3'
import store from './store/store'
import './assets/styles/index.css'
import './assets/styles/_global.scss'
import i18n from '@src/i18n.js'
import './design/index.scss'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel'
import { createMetaManager, useMeta } from 'vue-meta'
const app = createApp(App)

const metaManager = createMetaManager({
  esi: {
    group: true,
    namespaced: true,
    attributes: ['src', 'test', 'text'],
  },
})

useMeta(
  {
    keyName: 'page',
  },
  metaManager,
)

app.directive('i18n', {
  mounted(element, binding) {
    if (binding.expression)
      element.innerText = binding.instance.$i18n.tc(
        binding.expression + '.' + element.innerText.trim(),
      )
    else if (binding.value)
      element.innerText = binding.instance.$i18n.tc(binding.value + '.' + element.innerText.trim())

    element.innerText = binding.instance.$i18n.tc(element.innerText)
  },
})

app
  .use(store)
  .use(router)
  .use(metaManager)
  .use(i18n)
  .use(TawkMessengerVue, TAWK_TO_CONFIGS)
  .mount('#app')
