<template>
  <div v-if="currentIcon">
    <component :is="currentIcon" v-bind="{ ...$props, ...$attrs }" />
  </div>
</template>

<script setup>
import { ref, watch, markRaw } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
})

const currentIcon = ref(null)

const setIcon = () => {
  if (!props.icon) return

  import(`../../components/icons/${props.icon}.vue`).then((val) => {
    currentIcon.value = markRaw(val.default)
  })
}

watch(
  () => props.icon,
  () => {
    setIcon()
  },
)

setIcon()
</script>
