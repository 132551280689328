import { createStore } from 'vuex'
import { DEFAULT_LANGUAGE } from '@src/constants/general-constants'

export default createStore({
  state: {
    toastsList: [],
    currentSelectedLanguage: JSON.parse(localStorage.getItem('curLanguage')) || DEFAULT_LANGUAGE,
  },

  mutations: {
    NEW(state, payload) {
      const toast = {
        id: Math.random(),
        message: typeof payload === 'string' ? payload : payload.message,
        type: payload.type || 'default',
        duration: payload.duration || 5000,
        description: payload.description || null,
        sticky: payload?.stickey || false,
      }

      state.toastsList.push({ ...toast })
    },
    REMOVE(state, payload) {
      state.toastsList = state.toastsList.filter(({ id }) => {
        return id !== payload.id
      })
    },
  },
})
