
import {createI18n} from 'vue-i18n'
import enLang from '@src/locales/en.json'
import urLang from '@src/locales/ur.json'
import arLang from '@src/locales/ar.json'

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  allbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: { en: enLang, ur: urLang, ar: arLang },
})



