<template>
  <transition-group name="slide" tag="div" class="ui-toast-list component">
    <UiToast v-for="item in toastsList" :key="item.id" :item="item" @remove="onRemove" />
  </transition-group>
</template>

<script>
import UiToast from '@src/components/misc/UiToast.vue'
export default {
  name: 'UiToastList',

  components: {
    UiToast,
  },

  computed: {
    toastsList() {
      return this.$store.state.toastsList
    },
  },

  methods: {
    /**
     * commiting the id to store
     * @params {String} id
     */
    onRemove(id) {
      this.$store.commit('REMOVE', { id })
    },
    /**
     * Clear toasts list
     */
    clearToastList() {
      if (this.toastsList.length) {
        this.$store.commit('CLEAR_LIST')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-toast-list.component {
  position: fixed;
  top: 50px;
  right: 60px;
  z-index: 9999;
  border-radius: 5px;
}

@media (max-width: 350px) {
  .ui-toast-list.component {
    right: auto;
    left: 25px;
  }
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(0, 100%);
}
.slide-leave-to {
  transform: translate(200%, 0);
}
</style>
