export const API_URL = process.env.VUE_APP_API_URL || 'be.dev.myqampus.com'
export const URL_SCHEME = process.env.VUE_APP_URL_SCHEME || 'https'
export const SOCKET_SCHEME = process.env.VUE_APP_SOCKET_SCHEME || 'wss'
export const BASE_API_URL = process.env.VUE_APP_BASE_API_URL || `${URL_SCHEME}://${API_URL}`
export const ADMIN_API_URL = '/api/v1/'
export const TEAM_TENNAT_API_URL = '/tt_api/v1/'
export const I18N_LOCALE = 'en'
export const TAWK_TO_CONFIGS = {
  propertyId: process.env.VUE_APP_TAWK_TO_PROPERTY_ID || '64f9aa8eb2d3e13950ee7902',
  widgetId: process.env.VUE_APP_TAWK_TO_WIDGET_ID || '1h9nk4flr',
}
