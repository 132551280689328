<template>
  <div class="ui-toast component" :class="toastState.class">
    <div class="ui-toast__container">
      <div class="icon mt-1 ltr:mr-1 sm:ltr:mr-auto">
        <icon
          class="icon lrt:mr-10 rtl:ml-10"
          :icon="toastState.icon.name"
          :color="toastState.icon.color"
          height="19.5"
          width="19.5"
        />
      </div>
      <div class="toast ac-c ml-2">
        <div class="toast__heading">{{ item.message }}</div>
        <div v-if="item.description" class="toast__body">
          {{ item.description }}
        </div>
      </div>
      <div
        class="ui-toast__action rtl:left-4 rtl:right-auto ml-0.5"
        :class="{ 'ui-toast__action--centered': isCentered }"
      >
        <span class="lh-0" @click="remove">
          <icon icon="cross" height="9.3" width="9.3" :color="toastState.icon.color" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'
export default {
  name: 'UiToast',
  components: {
    icon,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  computed: {
    /*
     * Checks the position of the Ui-toast.
     * @param {void} Nothing.
     * @retuns {null/true} return value is based on the Boolean value of the "this.item.description" data property
     */
    isCentered() {
      return this.item.description ? null : true
    },

    /*
     * Sets the current state of the toast based on the value of the "this.item.type" data property.
     * @param {void} Nothing.
     * @retuns {object} state - the current state of the toast.
     */
    toastState() {
      let state = {
        class: 'default',
        icon: {
          name: 'check-circle',
          color: 'menu',
        },
      }

      switch (this.item.type) {
        case 'success':
          state = {
            class: 'success',
            icon: {
              name: 'check-circle',
              color: 'primary-green',
            },
          }
          break
        case 'warning':
          state = {
            class: 'warning',
            icon: {
              name: 'exclamation-circle',
              color: 'text-color-warning',
            },
          }
          break
        case 'error':
          state = {
            class: 'error',
            icon: {
              name: 'exclamation-circle',
              color: 'text-color-danger',
              // color: 'text-color-danger',
            },
          }
          break
        case 'notification':
          state = {
            class: 'notification',
            icon: {
              name: 'info-circle',
              color: 'text-color-info',
            },
          }
          break
        default:
          state = {
            class: 'default',
            icon: {
              name: 'exclamation-circle',
              color: 'menu',
            },
          }
          break
      }

      return state
    },
  },

  mounted() {
    if (this.item.duration) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        if (!this.item.sticky) this.remove()
      }, this.item.duration)
    }
  },

  methods: {
    remove() {
      this.$emit('remove', this.item.id)
    },
  },
}
</script>

<style lang="scss">
.ui-toast {
  position: relative;
  width: 384px;
  padding: 15px 10px;
  margin: 20px 0;

  /* background-color: $primary-white; */
  background-color: var(--primary-white);

  /* border: 1px solid $primary-grey; */
  border: 1px solid var(--primary-grey);
  border-radius: 5px;

  /* @include media(null, 575px) {
    width: 250px;
  } */
  .toast {
    flex-direction: column;
    &__heading {
      margin-top: 2px;

      /* @include font('Roboto', 1em, normal); */

      /* color: $label-text; */
      color: var(--label-text);
    }
    &__body {
      margin-top: 8px;

      /* @include font('Roboto', 0.8125em, normal); */

      /* color: $label-text; */
      color: var(--label-text);
    }
  }
  &.default {
    border: 1px solid;
  }
  &.success {
    background-color: var(--bg-success-light);
    border: 1px solid var(--primary-green);
    .toast__heading {
      color: var(--primary-green);
    }
  }
  &.info {
    background-color: var(--bg-color-info);
    border: 1px solid var(--text-color-info);
    .toast__heading {
      color: var(--bg-color-info);
    }
  }
  &.warning {
    background-color: var(--bg-warning-light);
    border: 1px solid var(--text-color-warning);
    .toast__heading {
      color: var(--text-color-warning);
    }
  }
  &.error {
    background-color: var(--bg-danger-light);
    border: 1px solid var(--text-color-danger);
    .toast__heading {
      color: var(--text-color-danger);
    }
  }
  &.notification {
    background-color: var(--bg-white);
    border: 1px solid var(--text-color);
    .toast__heading {
      color: var(--text-color-danger);
    }
  }
  &__container {
    display: flex;
  }
  &__action {
    top: 10px;
    right: 10px;
    span {
      cursor: pointer;
    }
    &--centered {
      transform: translateY(10px);
    }
  }
}

@media (max-width: 475px) {
  .ui-toast {
    width: 320px;
  }
}

@media (max-width: 400px) {
  .ui-toast {
    width: 280px;
  }
}
</style>
